import React, { useEffect, useState } from "react"
import Vimeo from "@u-wave/react-vimeo"
import Exit from "./svg/Exit"
import Loading from "../components/Loading"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Landing = ({ homePath, areVideosDoneLoading, timeLimit }) => {
  // const [paused, setPaused] = useState(false)
  // const [shrink, setShrink] = useState(isShrink)
  // const [audio, setAudio] = useState(false)
  // const [visible, setVisible] = useState(false)

  const [initialLoadFinished, setInitialLoadFinished] = useState(false)
  const [paused, setPaused] = useState(false)
  const [shrink, setShrink] = useState(false)
  const [audio, setAudio] = useState(false)
  const [fading, setFading] = useState(false)
  const [visible, setVisible] = useState(false)

  const [video1, setVideo1] = useState(false)
  const [video2, setVideo2] = useState(false)
  const [video3, setVideo3] = useState(false)
  const [video4, setVideo4] = useState(false)

  const breakpoints = useBreakpoint()

  useEffect(() => {
    if (homePath && initialLoadFinished) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [homePath])

  useEffect(() => {
    if (timeLimit && initialLoadFinished === false) {
      setShrink(true)
    }
  }, [timeLimit, initialLoadFinished])

  useEffect(() => {
    if (homePath === false) {
      setPaused(true)
    }
  }, [homePath])

  const audioHandler = () => {
    audio ? setAudio(false) : setAudio(true)
  }

  const pauseHandler = () => {
    paused === true ? setPaused(false) : setPaused(true)
  }

  const exitHandler = () => {
    setPaused(true)
    setVisible(false)
    setTimeout(() => {
      setShrink(true)
    }, 1000)
  }

  const shrinkHandler = () => {
    if (shrink) {
      setShrink(false)
      fadeHandler()
    } else {
      setShrink(true)
      setPaused(true)
      fadeHandler()
    }
  }

  // Handle fades on minimizing video
  const fadeHandler = () => {
    setFading(true)
    setTimeout(() => {
      setFading(false)
    }, 1000)
  }

  // Prevents videos from playing too fast
  const videoLoadHack = () => {
    setTimeout(() => {
      setPaused(true)
      setTimeout(() => {
        setInitialLoadFinished(true)
        if (homePath) {
          setVisible(true)
          setPaused(false)
        } else {
          setShrink(true)
          setTimeout(() => {
            setPaused(true)
          }, 500)
        }
      }, 700)
    }, 2000)
  }

  useEffect(() => {
    if (video1 && video2 && video3 && video4) {
      areVideosDoneLoading()
      videoLoadHack()
    }
  }, [video1, video2, video3, video4])

  return (
    <>
      <Loading
        visible={
          visible === false && timeLimit === false && homePath ? true : false
        }
      />
      <div
        id="shrink"
        className={
          (visible
            ? "visible opacity-100 "
            : "invisible opacity-0 pointer-events-none") +
          " " +
          (shrink ? "smallh smallw m-14" : "h-screen w-screen") +
          " " +
          "shadow3 absolute z-200 bg-grey transition-all duration-700 grid grid-cols-1 grid-rows-1 fixed top-0 right-0 overflow-hidden"
        }
      >
        <div className="transition-all h-full w-full duration-700 z-40 col-start-1 row-start-1 grid grid-cols-1 grid-rows-4 lg:grid-cols-2 lg:grid-rows-2">
          <div
            className={
              (breakpoints.aspect4
                ? "aspect4"
                : breakpoints.aspect3
                ? "aspect3"
                : breakpoints.aspect2
                ? "aspect2"
                : breakpoints.aspect1
                ? "aspect1"
                : "aspect") +
              " " +
              (shrink ? "shrink" : "") +
              " " +
              "transition-all duration-700"
            }
          >
            <Vimeo
              video="https://vimeo.com/582992744"
              // autoplay
              background
              responsive
              loop
              volume={audio ? 1 : 0}
              // onReady={readyHandler()}
              paused={paused}
              onPlay={() => {
                setVideo1(true)
              }}
            />
          </div>
          <div
            className={
              (breakpoints.aspect4
                ? "aspect4"
                : breakpoints.aspect3
                ? "aspect3"
                : breakpoints.aspect2
                ? "aspect2"
                : breakpoints.aspect1
                ? "aspect1"
                : "aspect") +
              " " +
              (shrink ? "shrink" : "") +
              " " +
              "transition-all duration-700"
            }
          >
            <Vimeo
              video="https://vimeo.com/582995101"
              // autoplay
              background
              responsive
              loop
              // volume={audio ? 1 : 0}
              // onReady={readyHandler()}
              paused={paused}
              onPlay={() => {
                setVideo2(true)
              }}
            />
          </div>
          <div
            className={
              (breakpoints.aspect4
                ? "aspect4"
                : breakpoints.aspect3
                ? "aspect3"
                : breakpoints.aspect2
                ? "aspect2"
                : breakpoints.aspect1
                ? "aspect1"
                : "aspect") +
              " " +
              (shrink ? "shrink" : "") +
              " " +
              "transition-all duration-700"
            }
          >
            <Vimeo
              video="https://vimeo.com/582994075"
              // autoplay
              background
              responsive
              loop
              // start={60}
              // volume={audio ? 1 : 0}
              // onReady={readyHandler()}
              paused={paused}
              start={147}
              onPlay={() => {
                setVideo3(true)
              }}
            />
          </div>
          <div
            className={
              (breakpoints.aspect4
                ? "aspect4"
                : breakpoints.aspect3
                ? "aspect3"
                : breakpoints.aspect2
                ? "aspect2"
                : breakpoints.aspect1
                ? "aspect1"
                : "aspect") +
              " " +
              (shrink ? "shrink" : "") +
              " " +
              "transition-all duration-700"
            }
          >
            <Vimeo
              video="https://vimeo.com/582992744"
              // autoplay
              background
              responsive
              loop
              start={84}
              // volume={audio ? 1 : 0}
              // onReady={readyHandler()}
              paused={paused}
              onPlay={() => {
                setVideo4(true)
              }}
            />
          </div>
        </div>
        <div
          className={
            (shrink ? "smallh smallw" : "h-full w-full") +
            " " +
            "relative col-start-1 col-span-2 row-span-2 row-start-1 flex flex-col z-300 transition-all"
          }
        >
          <div className="flex flex-row justify-between">
            <div></div>
            <button
              onClick={exitHandler}
              className={
                (fading ? "invisible opacity-0" : "visible opacity-100") +
                " " +
                (shrink ? "m-2" : "m-4") +
                " " +
                "text-white"
              }
            >
              <Exit white />
            </button>
          </div>
          <div onClick={shrinkHandler} className="flex-1"></div>
          <div
            className
            className="text-grey z-400 flex flex-row justify-between"
          >
            <button
              onClick={audioHandler}
              className={
                (fading ? "invisible opacity-0" : "visible opacity-100") +
                " " +
                (shrink ? "m-2" : "m-4") +
                " " +
                "transition-all"
              }
            >
              {audio ? "OFF" : "ON"}
            </button>
            <button
              onClick={pauseHandler}
              className={
                (fading ? "invisible opacity-0" : "visible opacity-100") +
                " " +
                (shrink ? "m-2" : "m-4") +
                " " +
                "transition-all"
              }
            >
              {paused ? "PLAY" : "PAUSE"}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Landing

// transition-all duration-300

{
  /* <video
            src={
              "https://player.vimeo.com/external/578225314.sd.mp4?s=e7993a703271b615582c559ed7de011256995e66&profile_id=165"
            }
            id="video2"
            className="w-full h-auto"
            autoplay="autoplay"
            muted
            loop
            playsinline
            webkit-playsinline
            controls
          ></video> */
}
