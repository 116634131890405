module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ette Objects","short_name":"ette","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"src/images/FAVICON.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ebb13b2afe5fb5fb13d752fe15e61803"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-head/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 320px)","sm":"(max-width: 720px)","md":"(max-width: 1026px)","l":"(max-width: 1536px)","vid3":"(max-height: 525px)","vid2":"(max-height: 600px)","vid1":"(max-height: 800px)","aspect4":"(min-aspect-ratio: 34/11)","aspect3":"(min-aspect-ratio: 170/69)","aspect2":"(min-aspect-ratio: 34/15)","aspect1":"(min-aspect-ratio: 68/39)","portrait":"(orientation: portrait)"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
