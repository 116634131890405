import React, { useEffect, useState } from "react"
import MobileLanding from "../components/MobileLanding"
import Landing from "../components/Landing"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const LandingWrapper = ({ children, location }) => {
  const [homePath, setHomePath] = useState(false)
  const [videosLoaded, setVideosLoaded] = useState(false)
  const [timeLimit, setTimeLimit] = useState(false)
  const [load, setLoad] = useState(true)
  const breakpoints = useBreakpoint()
  const pathname = typeof window !== "undefined" ? window.location.pathname : ""

  useEffect(() => {
    if (pathname === "/") {
      setHomePath(true)
    } else {
      setHomePath(false)
    }
  }, [pathname])

  useEffect(() => {
    if (videosLoaded === false && timeLimit === true) {
      setLoad(false)
    }
  }, [videosLoaded, timeLimit])

  setTimeout(() => {
    setTimeLimit(true)
  }, 7000)

  const areVideosDoneLoading = () => {
    setVideosLoaded(true)
  }

  return (
    <>
      {children}
      {(() => {
        if (breakpoints.md)
          return (
            <MobileLanding
              homePath={homePath}
              areVideosDoneLoading={areVideosDoneLoading}
              loaded={load}
              timeLimit={timeLimit}
            ></MobileLanding>
          )
        if (breakpoints.md === false) {
          return (
            <Landing
              homePath={homePath}
              areVideosDoneLoading={areVideosDoneLoading}
              timeLimit={timeLimit}
            ></Landing>
          )
        }
        // if (load === false) {
        //   return null
        // }
      })()}
    </>
  )
}

export default LandingWrapper
