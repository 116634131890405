// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

import React from "react"
import { AnimatePresence } from "framer-motion"
import LandingWrapper from "./src/components/LandingWrapper"

export const wrapPageElement = ({ element, props }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <LandingWrapper>{element}</LandingWrapper>
    </AnimatePresence>
  )
}
