import React from "react"

const Exit = ({ white }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M-0.25058 0.456521L0.456527 -0.250586L21.2506 20.5435L20.5435 21.2506L-0.25058 0.456521Z"
          fill={white ? "#FFFFFF" : "#231F20"}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.2506 0.456521L20.5435 -0.250586L-0.250591 20.5435L0.456516 21.2506L21.2506 0.456521Z"
          fill={white ? "#FFFFFF" : "#231F20"}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Exit
