import React from "react"
import { use100vh } from "react-div-100vh"

export default function Loading({ visible }) {
  const height = use100vh()
  return (
    <div
      style={{ height: height }}
      className={
        (visible
          ? "visible opacity-100 "
          : "invisible opacity-0 pointer-events-none") +
        " " +
        "bg-grey w-full z-150 fixed top-0 right-0 flex flex-col justify-center items-center transition-all duration-700"
      }
    >
      <div className="uppercase">Loading...</div>
    </div>
  )
}
